<template>
    <div class="practice-train">
<!--      <div class="practice-train-header">-->
<!--        课程筛选<el-cascader :props="cascaderProp" @change="selectCourse" style="margin-left: 10px" v-model="courseValue" :options="courseList"></el-cascader>-->
<!--      </div>-->
      <el-scrollbar class="main-left" :native="false">
        <div class="left-box">
          <div class="left-item" @click="selectCourse(0)"><span :class="courseValue ==  0? 'span-active text-overflow' : 'text-overflow'">全部课程</span></div>
          <div class="left-item" @click="selectCourse(item.course_id)" v-for="item in courseList"><span :class="item.course_id === courseValue ? 'span-active text-overflow' : 'text-overflow'">{{item.course_name}}</span></div>
        </div>
      </el-scrollbar>
      <div class="main-tight">
        <el-table class="customTable" :data="trainList" style="width: 100%; margin-top: 16px; flex: 1;" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
          <el-table-column prop="op_name" align="left" label="试题名称"></el-table-column>
          <el-table-column prop="assess_time" align="center" label="考核时间">
            <template slot-scope="scope">
              <span>{{scope.row.assess_time}}分钟</span>
            </template>
          </el-table-column>
          <el-table-column prop="total_num" align="center" label="训练次数">
            <template slot-scope="scope">
              <span>{{scope.row.total_num}}次</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-link class="reset-btn" @click="resetBtn(scope.row)" :underline="false">重置训练</el-link>
              <el-link type="success" :underline="false" @click="toStartTraining(scope.row.op_id,scope.row.exam_module_id, scope.row)">开始训练</el-link>
              <el-link type="primary" :underline="false" @click="toAnswerDetail(scope.row)">答题详情</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="listPagesCurrentChange">
        </el-pagination>
      </div>
    </div>
</template>

<script>
import {stuOpOpTrainList, getTeaSchoolCourse, stuOpOpResetTrain, reset_drill, resetKefuTrain} from '@/utils/apis'

export default {
  name: "Practice",
  data(){
    return {
      courseValue:'',
      courseList:[],
      trainList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      cascaderProp:{value:'id',label:'name',children:'child'},
      course_id:'', //课程id
      chapter_index:'', //章节id
      node_index:'', //节id
    }
  },
  mounted() {
    this.getCourseList();
    // this.getTrainList()
  },
  methods:{
    //获取课程
    getCourseList(){
      getTeaSchoolCourse({}).then((res)=>{
        this.courseList = res.data;
        this.getTrainList();
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    getTrainList(){
      let params = {
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum,
      }
      if(this.courseValue){
        params.course_id = this.courseValue
      }
      stuOpOpTrainList(params).then((res)=>{
        this.trainList = res.data.list;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    // 筛选课程
    selectCourse(id){
      this.listPages.currentPageNum = 1;
      this.courseValue = id;
      this.getTrainList()
    },
    resetBtn(row){
      const confirmText = ['1.重置训练将会清空当前训练数据，学生可以重新开始训练','2.每一次训练成绩详情可在答题详情进行查看'];
      const newDatas = [];
      const h = this.$createElement;
      for (let i in confirmText) {
        newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示信息', {
        title:'提示信息',
        message: h('div', null, newDatas),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
      }).then(()=>{
        let param = {}
        switch (row.exam_module_id) {
            //网络客户
            case 42:
            // case 43:
                param = {
                    op_id: row.op_id,
                    course_id: row.course_id,
                    chapter: row.chapter_index,
                    node: row.node_index
                }
                reset_drill(param).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1500,
                            onClose:()=>{
                                this.getTrainList()
                            }
                        });
                    }
                })
            break;

            // case 43: // 三级客服
            // case 21: // 四级客服
            //     param = {
            //       id: row.op_id,
            //       course_id: row.course_id,
            //       chapter: row.chapter_index,
            //       node: row.node_index
            //     }
            //   resetKefuTrain(param).then(res => {
            //         if (res.code === 200) {
            //             this.$message({
            //                 type: 'success',
            //                 message: res.msg,
            //                 duration: 1500,
            //                 onClose:()=>{
            //                     this.getTrainList()
            //                 }
            //             });
            //         }
            //     })
            // break;

            default:
                param = {
                    op_id: row.op_id,
                    course_id: row.course_id,
                    chapter_index: row.chapter_index,
                    node_index: row.node_index
                }
                stuOpOpResetTrain(param).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1500,
                            onClose:()=>{
                                this.getTrainList()
                            }
                        });
                    }
                })
                break;
        }
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消重置'
        });
      })
    },
    // 开始训练
    toStartTraining(id,moduleId, item){
      switch (moduleId){
        // 商品信息采集与处理
        case 8:
        case 9:
        case 10:
          this.$router.push({
            path:'/student/trainCenter/goodsInfo/startTraining',
            query:{
              id:id,
              course_id: item.course_id,
            }
          });
          break;
        // 网店管理
        case 15:
        case 16:
        case 18:
          this.$router.push({
            path:'/student/trainCenter/startTraining',
            query:{
              id:id,
              course_id: item.course_id,
            }
          });
          break;
        // 客服
        case 21:
          this.$router.push({
            path:'/student/trainCenter/startTraining/onlineShopCustomerService',
            query:{
              id:id,
              course_id: item.course_id,
              chapter: item.chapter_index,
              node: item.node_index,
            }
          });
          break;
        // 数据收集与清洗
        case 22:
        case 23:
          this.$router.push({
            path:'/student/trainCenter/startTraining/ECommerceDataCollectionAndCleaning',
            query:{
              op_id:id,
              course_id: item.course_id,
              chapter: item.chapter_index,
              node: item.node_index
            }
          });
          break;
        // 网店装修
        case 11:
        case 12:
        case 13:
        case 14:
          this.$router.push({
            path:'/student/trainCenter/startTraining/onlineStoreFitment',
            query:{
              id:id,
              course_id:item.course_id
            }
          });
          break;
        // 网络推广
        case 19:
        case 20:
          this.$router.push({
            path:'/student/trainCenter/startTraining/networkPromotion',
            query:{
                id:id,
                course_id:item.course_id,
            }
          });
          break;
        //网络客户
        case 42:
        case 43:
          this.$router.push({
            path:'/student/trainCenter/startTraining/cyberCustomerService',
            query:{
              id:id,
              course_id:item.course_id,
              chapter: item.chapter_index,
              node: item.node_index
            }
          });
          break;
        // 三级 网络营销
        case 33:
        case 34:
        case 35:
        case 36:
        case 37:
        case 38:
          this.$router.push({
            path:'/student/trainCenter/startTraining/internetMarketing',
            query:{
              op_id:id,
              course_id: item.course_id,
              chapter: item.chapter_index,
              node: item.node_index
            }
          });
          break;
        // 三级 网络营销
        case 44:
        case 45:
        case 46:
          this.$router.push({
            path:'/student/trainCenter/startTraining/ECommerceDataAnalysisAndApplication',
            query:{
              op_id:id,
              course_id: item.course_id,
              chapter: item.chapter_index,
              node: item.node_index
            }
          });
          break;
        //  网上交易管理
        case 39:
        case 40:
        case 41:
          this.$router.push({
            path:'/student/trainCenter/startTraining/onlineTrading',
            query:{
              op_id:id,
              course_id: item.course_id,
              chapter: item.chapter_index,
              node: item.node_index
            }
          });
          break;
          // 三级 网店视觉设计
          case 30:
          case 31:
          case 32:
              this.$router.push({
                  path:'/student/trainCenter/onlineShopVisualDesign/index',
                  query:{
                      id:id,
                      course_id: item.course_id,
                  }
              });
              break;
      }
    },
    // 答题详情
    toAnswerDetail(row){
      this.$router.push({
        path:'/student/trainCenter/answerDetail',
        query:{
          id:row.op_id,
          moduleId:row.exam_module_id,
          course_id:row.course_id,
          trainName:row.op_name,
          chapter:row.chapter_index,
          node:row.node_index,
        }
      })
    },
    listPagesCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getTrainList();
    }
  }
}
</script>

<style scoped lang="scss">
.practice-train{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  .main-left{
    z-index: 98;
    //background: #F6F7FC;
    position: relative;
    top: -20px;
    height: calc(100% + 20px);
    border-right: 20px solid #F6F7FC;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .left-box{
      width: 210px;
      display: flex;
      flex-direction: column;
      .left-item{
        width: 100%;
        cursor: pointer;
        line-height: 1;
        margin: 16px 0;
        display: flex;
        span{
          line-height: 1;
          color: #666;
          font-size: 14px;
        }
        .span-active{
          color: #1AB163;
        }
      }
    }
  }
  .main-tight{
    flex: 1;
    width: 1%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
}
.reset-btn{
  &:hover{
    color: #606266;
  }
}
</style>